/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
@media (min-width: 768px) {
  .grid.grid_cols_2 {
    width: calc(100% + 32px);
  }
}

@media (min-width: 768px) {
  .grid.grid_cols_2 > .grid__item {
    width: calc(50% - 32px);
    margin-right: 32px;
  }
  .grid.grid_cols_2 > .grid__item:last-child {
    margin-right: 0;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
@media (min-width: 1200px) {
  .grid.grid_layout_1-3_2-3 > .grid__item:nth-child(2n-1) {
    width: 33.3333333333%;
    padding-right: 16px;
  }
  .grid.grid_layout_1-3_2-3 > .grid__item:nth-child(2n) {
    width: 66.6666666667%;
    padding-left: 16px;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.grid.grid_layout_2-3_1-3.grid_reverse_mobile {
  flex-direction: column-reverse;
}
@media (min-width: 1200px) {
  .grid.grid_layout_2-3_1-3.grid_reverse_mobile {
    flex-direction: row;
  }
  .grid.grid_layout_2-3_1-3 > .grid__item:nth-child(2n-1) {
    width: 66.6666666667%;
    padding-right: 16px;
  }
  .grid.grid_layout_2-3_1-3 > .grid__item:nth-child(2n) {
    width: 33.3333333333%;
    padding-left: 16px;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
@media (min-width: 768px) {
  .section {
    padding: 120px 0;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.section__actions .button + .button {
  margin-top: 16px;
}
@media (min-width: 1024px) {
  .section__actions .button + .button {
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .section__actions {
    padding-top: 32px;
    text-align: right;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.section_theme_darkest {
  color: #fff;
  background-color: #dbdbdb;
}
.section_theme_darkest .section__heading {
  color: #1F2E4F;
}
.section_theme_darkest .section__subheading {
  color: #333;
}
.section_theme_darkest .section__preheading {
  color: #7d7d84;
}
.section_theme_darkest .section__divider {
  background-color: #205CE1;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.section_theme_highlight {
  background-color: #ececec;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
#who-story .section__content {
  background-color: rgba(31, 46, 79, 0.6);
  padding: 40px;
  max-width: 600px;
  color: #fff;
}
#who-story .section__heading {
  color: #fff;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
#properties-story .section__content {
  padding-top: 0;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
#home-banner-story.section {
  padding-top: 70px;
  padding-bottom: 70px;
}
#home-banner-story.section .section__content {
  max-width: 500px;
  color: #fff;
}
#home-banner-story.section .section__heading {
  color: #fff;
}
#home-banner-story.section .section__background-image > img {
  position: absolute;
}
@media (min-width: 320px) {
  #home-banner-story.section .section__background-overlay {
    background-color: transparent;
  }
  #home-banner-story.section .section__heading {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  #home-banner-story.section .section__heading {
    font-size: 32px;
  }
}
@media (min-width: 1200px) {
  #home-banner-story.section {
    padding: 100px !important;
  }
}
@media (min-width: 1600px) {
  #home-banner-story.section {
    padding: 190px !important;
  }
  #home-banner-story.section .image > img {
    width: 100%;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.button {
  display: inline-block;
  height: auto;
  min-height: 60px;
  line-height: 60px;
  text-decoration: none;
  text-align: center;
  padding: 0 48px;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  border: none;
  appearance: none;
  font-size: 16px;
  color: #fff;
  background: #205CE1;
}
.button:hover {
  color: #fff;
  background: #1849B4;
}
.button_size_small {
  height: auto;
  min-height: 40px;
  line-height: 40px;
  padding: 0 24px;
}
.button_width_full {
  width: 100%;
}

.button {
  font-weight: bold;
}
.button_size_small {
  height: auto;
  min-height: 50px;
  line-height: 50px;
}

.button {
  text-transform: uppercase;
}
.button_size_small {
  background: #1F2E4F;
  border-radius: 1px;
  font-weight: 100;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
}
.button_size_small:hover {
  background: #1F2E4F;
}

.button > svg {
  fill: #fff;
  width: 16px;
  display: inline-block;
  vertical-align: middle;
}

.button:hover > svg {
  fill: #fff;
}

.button > span + svg,
.button > svg + span {
  margin-left: 8px;
}

.favorite-toggle > svg:nth-child(2) {
  display: none;
}

.favorite-toggle.favorite-toggle_state_active > svg:nth-child(1) {
  display: none;
}
.favorite-toggle.favorite-toggle_state_active > svg:nth-child(2) {
  display: inline-block;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.image_corners_rounded-2 {
  border-radius: 2px;
}

.image_corners_rounded-3 {
  border-radius: 3px;
}

.image_corners_rounded-4 {
  border-radius: 4px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.autocomplete {
  position: relative;
  color: #333;
}
.autocomplete__input {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 48px;
  border: 2px solid #c3c3c4;
  border-radius: 4px;
  background: #fff;
  padding: 12px 10px;
}
.autocomplete__input > input {
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  flex: 100;
  width: 100%;
  min-width: 100px;
  font-size: inherit;
}
.autocomplete__selected {
  color: #fff;
  background: #205CE1;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 2px 0 2px 8px;
  border-radius: 2px;
}
.autocomplete__remove {
  display: inline-block;
  cursor: pointer;
  padding: 0 8px;
}
.autocomplete__results {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.autocomplete__result {
  cursor: pointer;
  padding: 12px 10px;
  color: #333;
}
.autocomplete__result:hover {
  color: #333;
  background: #e6e6e6;
}
.autocomplete__result_town, .autocomplete__result_type_town {
  font-weight: bold;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.checkbox-list {
  position: relative;
  color: #333;
}
.checkbox-list__text {
  position: relative;
  min-height: 32px;
  line-height: 32px;
  padding: 7px 10px;
  border-radius: 3px;
  border: 2px solid #c3c3c4;
  background: #fff;
  cursor: pointer;
}
.checkbox-list__text:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23333333"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
  width: 8px;
  height: 16px;
  right: 20px;
}
.checkbox-list__count {
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 2px;
  margin-left: 4px;
  color: #fff;
  background: #205CE1;
}
.checkbox-list__options {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.checkbox-list__option {
  position: relative;
  cursor: pointer;
  padding: 12px 10px;
}
.checkbox-list__box {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 16px;
  height: 16px;
  border: 1px solid #c3c3c4;
  background: #fff;
  border-radius: 2px;
}
.checkbox-list__option.checkbox-list__option_group {
  font-weight: bold;
  text-transform: uppercase;
  cursor: initial;
}
.checkbox-list__option.checkbox-list__option_group > .checkbox-list__box {
  display: none;
}
.checkbox-list__option.checkbox-list__option_checked > .checkbox-list__box {
  border-color: #205CE1;
  background: #205CE1;
}
.checkbox-list_opened > .checkbox-list__options {
  display: block;
}
.checkbox-list_opened > .checkbox-list__text:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23333333"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/></svg>');
}

.dropdown {
  position: relative;
  color: #333;
}
.dropdown__value {
  position: relative;
  min-height: 32px;
  line-height: 32px;
  padding: 7px 40px 7px 12px;
  border-radius: 4px;
  border: 2px solid #c3c3c4;
  background: #fff;
  cursor: pointer;
}
.dropdown__value:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23333333"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
  width: 8px;
  height: 16px;
  right: 20px;
}
.dropdown__options {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.dropdown__option {
  cursor: pointer;
  padding: 12px;
  color: #333;
}
.dropdown__option:hover {
  color: #333;
  background: #e6e6e6;
}
.dropdown__option.dropdown__option_state_selected {
  color: #fff;
  background: #1F2E4F;
}
.dropdown_state_opened > .dropdown__value:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23333333"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/></svg>');
}
.dropdown_state_closed > .dropdown__options {
  display: none;
}
.dropdown_state_opened > .dropdown__options {
  display: block;
}

.switcher {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  color: #333;
}
.switcher__control {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 48px;
  height: 28px;
  border-radius: 16px;
  background-color: #efeeef;
  transition: background-color 0.3s ease;
}
.switcher__control:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  transition: background-color 0.3s ease, left 0.3s ease;
}
.switcher__label {
  display: inline-block;
  margin-left: 12px;
}
.switcher_state_checked > .switcher__control {
  background-color: #1F2E4F;
}
.switcher_state_checked > .switcher__control:after {
  left: 24px;
  background-color: #fff;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.search-bar {
  position: relative;
}
.search-bar__container {
  width: 280px;
  margin: 0 auto;
}
.search-bar__main-options {
  position: relative;
  padding: 16px;
  border-radius: 3px;
  background-color: #fff;
}
.search-bar__main-options .dropdown__value,
.search-bar__main-options .checkbox-list__text,
.search-bar__main-options .autocomplete__input {
  background-color: transparent;
  border: none;
  padding: 0;
}
.search-bar__main-options .autocomplete__input {
  min-height: 32px;
}
.search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list {
  border-bottom: 1px solid #ececec;
}
.search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list,
.search-bar__main-options .autocomplete {
  padding: 32px 0 16px;
}
.search-bar__main-options .dropdown:after,
.search-bar__main-options .checkbox-list:after,
.search-bar__main-options .autocomplete:after {
  position: absolute;
  top: 16px;
  left: 0;
  font-size: 11px;
  font-weight: bold;
  color: #1F2E4F;
}
.search-bar__main-options .dropdown:after {
  content: "STATUS";
}
.search-bar__main-options .checkbox-list:after {
  content: "TYPE";
}
.search-bar__main-options .autocomplete:after {
  content: "LOCATION";
}
.search-bar__secondary-options {
  display: none;
  margin-top: 64px;
  padding: 16px 16px 0;
  background-color: #fff;
  border-radius: 3px;
  border-top: 4px solid transparent;
}
.search-bar__secondary-options .dropdown__value,
.search-bar__secondary-options .checkbox-list__text {
  border: none;
  background-color: #efefef;
  border-radius: 2px;
  min-height: 24px;
  line-height: 24px;
}
.search-bar__secondary-options .switcher {
  display: flex;
  margin-top: 16px;
}
.search-bar__secondary-options .dropdown__value,
.search-bar__secondary-options .dropdown__options,
.search-bar__secondary-options .checkbox-list__text,
.search-bar__secondary-options .checkbox-list__options,
.search-bar__secondary-options .switcher {
  font-size: 14px;
}
.search-bar__secondary-options .dropdown + .dropdown,
.search-bar__secondary-options .dropdown + .checkbox-list {
  margin-top: 12px;
}
.search-bar__secondary-options_state_open {
  display: block;
}
.search-bar__options {
  position: absolute;
  bottom: -32px;
  right: 0;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  border-bottom: 1px dotted #fff;
}
.search-bar__options:hover {
  color: #fff;
  border-color: #fff;
}
.search-bar__submit {
  padding: 16px 0;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: #205CE1;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.search-bar__submit:hover {
  color: #fff;
  background-color: #1849B4;
}
.search-bar .clearfix {
  display: block;
  width: 100%;
}
@media (min-width: 375px) {
  .search-bar__container {
    width: 320px;
  }
}
@media (min-width: 425px) {
  .search-bar__container {
    width: 350px;
  }
}
@media (min-width: 768px) {
  .search-bar__container {
    width: 720px;
  }
  .search-bar__main-options {
    display: flex;
    padding: 0 0 0 16px;
  }
  .search-bar__main-options .dropdown {
    flex-basis: 160px;
    margin-right: 16px;
  }
  .search-bar__main-options .checkbox-list {
    flex-basis: 180px;
    margin-right: 16px;
  }
  .search-bar__main-options .autocomplete {
    flex-basis: 240px;
  }
  .search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list {
    border-bottom: none;
  }
  .search-bar__main-options .dropdown:before,
.search-bar__main-options .checkbox-list:before {
    content: "";
    position: absolute;
    top: 24px;
    right: 0;
    width: 1px;
    height: 32px;
    border-right: 1px solid #ececec;
  }
  .search-bar__submit {
    flex-grow: 1;
    padding-top: 28px;
    border-radius: 0 3px 3px 0;
  }
  .search-bar__secondary-options_state_open {
    display: flex;
    flex-wrap: wrap;
  }
  .search-bar__secondary-options_state_open .dropdown,
.search-bar__secondary-options_state_open .checkbox-list {
    flex-basis: calc(50% - 8px);
    flex-shrink: 1;
    flex-grow: 0;
    margin-bottom: 16px;
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(2n),
.search-bar__secondary-options_state_open .checkbox-list:nth-child(2n) {
    margin-right: 0;
  }
  .search-bar__secondary-options_state_open .dropdown + .dropdown,
.search-bar__secondary-options_state_open .dropdown + .checkbox-list {
    margin-top: 0;
  }
  .search-bar__secondary-options_state_open .switcher {
    margin-top: 0;
    margin-bottom: 16px;
    margin-right: 32px;
  }
}
@media (min-width: 1024px) {
  .search-bar__container {
    width: 920px;
  }
  .search-bar__main-options .checkbox-list {
    flex-basis: 225px;
  }
  .search-bar__main-options .autocomplete {
    flex-basis: 355px;
  }
  .search-bar__secondary-options_state_open {
    display: flex;
    flex-wrap: wrap;
  }
  .search-bar__secondary-options_state_open .dropdown,
.search-bar__secondary-options_state_open .checkbox-list {
    flex-basis: calc(25% - 12px);
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(2n),
.search-bar__secondary-options_state_open .checkbox-list:nth-child(2n) {
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(4n),
.search-bar__secondary-options_state_open .checkbox-list:nth-child(4n) {
    margin-right: 0;
  }
  .search-bar__options {
    right: 22px;
  }
}

.search-bar {
  text-transform: uppercase;
}
.search-bar .dropdown__value {
  color: #000;
}
.search-bar .checkbox-list__text {
  color: #000;
}

.search-bar__submit {
  text-transform: uppercase;
  background: #1F2E4F;
  border-radius: 1px;
  font-weight: 400;
}

.search-bar__submit:hover {
  background: #1F2E4F;
}

.search-bar__main-options .dropdown:after {
  font-family: "PT Sans";
  font-size: 14px;
}

.search-bar__main-options .checkbox-list:after {
  font-family: "PT Sans";
  font-size: 14px;
}

.search-bar__main-options .autocomplete:after {
  font-family: "PT Sans";
  font-size: 14px;
}

.autocomplete__input > input {
  text-transform: uppercase;
}

.search-bar__options {
  border-bottom: none;
  font-size: 16px;
  text-transform: uppercase;
}

.search-bar__secondary-options .checkbox-list__text, .search-bar__secondary-options .dropdown__value {
  background-color: #fbfbfb;
  border: 1px solid #eee;
}

.dropdown__option_type_single {
  color: #4a4a4a;
}

.switcher__label {
  color: #4a4a4a;
}

.checkbox-list__label {
  color: #4a4a4a;
}

.checkbox-list__text:after, .dropdown__value:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23000000"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
}

.checkbox-list_opened > .checkbox-list__text:after, .dropdown_state_opened > .dropdown__value:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23000000"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/></svg>');
}

@media (min-width: 320px) {
  .search-bar {
    font-size: 14px;
  }

  .search-bar__submit {
    font-size: 14px;
  }
}
@media (min-width: 425px) {
  .search-bar {
    font-size: 16px;
  }

  .search-bar__submit {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .search-bar {
    font-size: 12px;
  }

  .search-bar__submit {
    font-size: 12px;
  }
}
@media (min-width: 1024px) {
  .search-bar {
    font-size: 16px;
  }

  .search-bar__submit {
    height: 55px;
    padding-top: 16px;
    margin-top: 12px;
    margin-right: 12px;
    width: 140px;
    font-size: 16px;
  }
}
.hero {
  position: relative;
}
.hero__heading, .hero__subheading {
  position: relative;
  padding: 0 16px;
  max-width: 540px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  margin: 0 auto 48px;
}
.hero__heading + .hero__subheading {
  margin-top: -32px;
}
.hero__actions {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .hero__heading, .hero__subheading {
    max-width: 100%;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.gallery__canvas {
  position: relative;
}

.gallery__images, .gallery__image, .gallery__shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gallery__image {
  opacity: 0;
}

.gallery__image_with_transition {
  transition: opacity 0.3s ease;
}

.gallery__image_timeline_current {
  opacity: 1;
}

.gallery__shadow {
  background: rgba(0, 0, 0, 0.3);
}

.gallery__dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  width: 80%;
  text-align: center;
}

.gallery__dot {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}

.gallery__dot_state_active {
  background-color: #221e1f;
}

.gallery__dot + .gallery__dot {
  margin-left: 8px;
}

.gallery__control {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  border-radius: 50%;
  background-color: #cacaca;
  cursor: pointer;
}

.gallery__control > svg {
  fill: #fff;
  width: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery__control:hover {
  background-color: #1F2E4F;
}

.gallery__control:hover > svg {
  fill: #fff;
}

.gallery__control_type_prev {
  left: 0;
  transform: translate(-25%, -50%);
}

.gallery__control_type_next {
  right: 0;
  transform: translate(25%, -50%);
}

.gallery[data-with-origin] .gallery__image {
  cursor: pointer;
}

.gallery__stripe {
  display: flex;
  margin-top: 12px;
  font-size: 14px;
  color: #333;
}

.gallery__title {
  flex: 1 1 0;
}

.gallery__counter {
  flex: 0 0 auto;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.hero__gallery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero__gallery > .gallery__canvas {
  width: 100%;
  height: 100%;
}

.hero .gallery__dots {
  display: none;
}

.hero__heading + .hero__search-bar {
  margin-bottom: 48px;
  padding-top: 0;
}

.hero_type_gallery-and-search {
  width: 100%;
  flex-basis: 780px;
  flex-shrink: 0;
}

.hero_type_gallery-and-search > .hero__heading {
  text-align: center;
  font-size: 36px;
}

.hero_type_gallery-and-search > .hero__subheading {
  text-align: center;
  font-size: 24px;
  margin-bottom: 0;
}

@media (min-width: 375px) {
  .hero_type_gallery-and-search {
    flex-basis: 730px;
  }
}
@media (min-width: 425px) {
  .hero_type_gallery-and-search {
    flex-basis: 680px;
  }
}
@media (min-width: 768px) {
  .hero_type_gallery-and-search {
    flex-basis: 510px;
    padding-top: 150px;
  }

  .hero .gallery__dots {
    display: block;
  }
}
.hero_type_gallery-and-search .hero__heading {
  color: #fff;
  font-weight: 100;
  font-size: 32px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.hero_type_gallery-and-search .gallery__shadow {
  background: none;
}

@media (min-width: 320px) {
  .hero_type_gallery-and-search {
    flex-basis: 100px;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .hero_type_gallery-and-search {
    min-height: 200px;
  }

  .hero_type_gallery-and-search .hero__heading {
    padding-top: 50px;
  }
}
@media (min-width: 768px) {
  .hero_type_gallery-and-search {
    flex-basis: 100px;
    padding-top: 20px !important;
  }

  .hero_type_gallery-and-search .hero__heading {
    padding-top: 120px;
  }
}
@media (min-width: 1024px) {
  .hero_type_gallery-and-search {
    flex-basis: 100px;
    padding-top: 100px !important;
  }
}
@media (min-width: 1600px) {
  .hero_type_gallery-and-search {
    flex-basis: 100px;
    padding-top: 200px !important;
  }
}
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.services {
  background-color: #1F2E4F;
  padding: 40px 0;
}

.service {
  text-align: center;
  text-decoration: none;
  background-color: #1F2E4F;
}
.service:hover {
  background-color: none;
}
.service__icon {
  width: 64px;
  margin: 0 auto 16px;
  fill: #fff;
}
.service__heading {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
}
.service__description {
  color: #c0c2c6;
}
@media (min-width: 768px) {
  .service {
    display: flex;
    text-align: left;
    align-items: center;
  }
  .service__icon {
    flex: 0 0 64px;
    margin: 0;
  }
  .service__details {
    margin-left: 24px;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.service.service_type_column {
  display: block;
  text-align: center;
}
.service.service_type_column .service__icon {
  margin: 0 auto 16px;
}
.service.service_type_column .service__icon > svg {
  position: relative;
  top: 0;
  transform: none;
}

.service__heading {
  font-family: "PT Sans";
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.neighborhood {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  display: block;
}
.neighborhood_size_large, .neighborhood_size_medium, .neighborhood_size_small {
  padding-bottom: 100%;
}
.neighborhood__image {
  position: absolute;
  height: 100%;
  transition: transform 0.3s ease-in-out;
}
.neighborhood__details {
  position: absolute;
  width: 100%;
  bottom: 32px;
  z-index: 2;
  text-align: center;
}
.neighborhood__details:before {
  content: "";
  display: block;
  margin: 0 auto 8px;
  width: 200px;
  height: 1px;
  background: #fff;
}
.neighborhood__area {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.neighborhood__count {
  color: #fff;
}
.neighborhood__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 40%, rgba(0, 0, 0, 0.75) 100%);
}
.neighborhood:hover .neighborhood__image {
  transform: scale(1.1);
}
@media (min-width: 768px) {
  .neighborhood_size_large {
    padding-bottom: 200%;
  }
  .neighborhood_size_small {
    padding-bottom: 50%;
  }
}

@media (min-width: 768px) {
  .neighborhoods__group .neighborhood_size_medium {
    padding-bottom: calc(100% - 16px);
  }

  .neighborhoods__group .neighborhood_size_small {
    padding-bottom: calc(50% - 16px);
  }
}
.neighborhoods .section__subheading {
  color: #333;
}
.neighborhoods .neighborhood__area {
  font-family: "PT Sans";
  font-weight: 400;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.slider__canvas {
  position: relative;
}
.slider__grid {
  position: relative;
  display: flex;
  align-items: center;
  transition: transform 0.5s ease;
}
.slider__item {
  flex: 0 0 100%;
}
.slider__item + .slider__item {
  margin-left: 100%;
}
.slider__dots {
  text-align: center;
  padding: 16px 0;
  user-select: none;
}
.slider__dot {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #ddd;
  cursor: pointer;
}
.slider__dot_state_active {
  background-color: #1F2E4F;
}
.slider__dot + .slider__dot {
  margin-left: 8px;
}
.slider__control {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #cacaca;
  cursor: pointer;
}
.slider__control > svg {
  fill: #fff;
  width: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slider__control:hover {
  background-color: #1F2E4F;
}
.slider__control:hover > svg {
  fill: #fff;
}
.slider__control_type_prev {
  left: 4px;
}
.slider__control_type_next {
  right: 4px;
}

.slider .slider__dot {
  width: 10px;
  height: 10px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.slider.slider_type_limited .slider__canvas {
  overflow: hidden;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.slider.slider_controls_top .slider__canvas {
  padding-top: 48px;
}
.slider.slider_controls_top .slider__control {
  border-radius: 4px;
  top: 0;
  width: 32px;
  height: 32px;
  transform: none;
}
.slider.slider_controls_top .slider__control_type_prev {
  left: auto;
  right: 40px;
}
.slider.slider_controls_top .slider__control_type_next {
  right: 0;
}
.slider.slider_controls_top .slider__control > svg {
  width: 16px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.slider.slider_type_properties .slider__grid {
  align-items: normal;
}
.slider.slider_type_properties .property-card {
  height: calc(100% - 32px);
}
.slider.slider_type_properties .slider__item {
  flex-basis: 90%;
}
.slider.slider_type_properties .slider__item + .slider__item {
  margin-left: 16px;
}
@media (min-width: 768px) {
  .slider.slider_type_properties .slider__item {
    flex-basis: 70%;
  }
}
@media (min-width: 1024px) {
  .slider.slider_type_properties .slider__item {
    flex-basis: 40%;
  }
}
@media (min-width: 1200px) {
  .slider.slider_type_properties .slider__item {
    flex-basis: calc(33.3333333333% - 10.6666666667px);
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.slider.slider_type_agents-large .slider__dots {
  padding-top: 48px;
}
@media (min-width: 768px) {
  .slider.slider_type_agents-large .slider__item {
    flex-basis: 50%;
  }
  .slider.slider_type_agents-large .slider__item + .slider__item {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .slider.slider_type_agents-large .slider__item {
    flex-basis: 25%;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.property-card {
  display: block;
  position: relative;
  border-radius: 4px;
  border: 1px solid #efefef;
  text-decoration: none;
  overflow: hidden;
  color: #333;
  background-color: #fff;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.05);
}
.property-card__visuals {
  position: relative;
}
.property-card__details {
  padding: 8px 16px 16px;
}
.property-card__favorite {
  position: absolute;
  bottom: 12px;
  right: 16px;
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
}
.property-card__favorite > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  fill: currentColor;
}
.property-card__favorite:hover {
  color: #fff;
}
.property-card__area {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 4px;
}
.property-card__features {
  display: flex;
  flex-wrap: wrap;
  padding: 4px 0;
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-right: 0;
  border-left: 0;
}
.property-card__feature {
  display: flex;
  flex: 0 0 auto;
  line-height: 32px;
  margin-right: 12px;
}
.property-card__feature:last-child {
  margin-right: 0;
}
.property-card__feature-icon {
  margin-right: 8px;
}
.property-card__feature-icon > svg {
  position: relative;
  top: -2px;
  width: 20px;
  vertical-align: middle;
  fill: #333;
}
.property-card__feature-value {
  font-size: 14px;
}
.property-card__feature_type_beds svg {
  top: -1px;
  width: 24px;
}
.property-card__feature_type_baths svg {
  width: 18px;
}
.property-card__feature_type_stand svg {
  width: 22px;
  top: 0;
}
.property-card__feature_type_building svg {
  top: 0;
}
.property-card__tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding-bottom: 8px;
}
.property-card__tag, .property-card__price {
  padding: 4px 8px;
  border-radius: 2px;
}
.property-card__price {
  font-size: 14px;
  line-height: 20px;
}
.property-card__tag {
  color: #fff;
  background: #1F2E4F;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
}
.property-card__price {
  position: absolute;
  bottom: 16px;
  left: 16px;
  z-index: 1;
  color: #fff;
  background-color: #1F2E4F;
}
.property-card__summary {
  font-size: 14px;
}
.property-card__heading {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-top: 4px;
}
.property-card__description {
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}

.property-card__address {
  display: flex;
  margin-top: 12px;
  font-size: 14px;
  font-style: italic;
}
.property-card__address-icon {
  width: 16px;
  margin-right: 4px;
}

.property-card__address_type_country svg {
  position: relative;
  top: 2px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.property-card__photos {
  position: relative;
}
.property-card__photo {
  position: relative;
  width: 100%;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.property-card__photo > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.property-card__control {
  position: absolute;
  top: 0;
  width: 60px;
  height: 100%;
}
.property-card__control > svg {
  width: 10px;
  fill: #fff;
  filter: drop-shadow(0rem 0.125rem 0.1875rem rgba(0, 0, 0, 0.25));
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.property-card__control:hover > svg {
  fill: #1F2E4F;
}
.property-card__control_type_prev {
  left: 0;
}
.property-card__control_type_prev > svg {
  left: 8px;
}
.property-card__control_type_next {
  right: 0;
}
.property-card__control_type_next > svg {
  right: 8px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.agent-card {
  text-align: center;
  text-decoration: none;
}
.agent-card__photo {
  width: 200px;
  margin: 0 auto 16px;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.agent-card__photo > img {
  object-position: top;
}
.agent-card__nophoto {
  width: 200px;
  height: 200px;
  padding: 0;
  background-color: transparent;
}
.agent-card__nophoto > svg {
  fill: #333;
}
.agent-card__name {
  margin-bottom: 4px;
  color: #1F2E4F;
  font-weight: bold;
}
.agent-card__name:before {
  content: "";
  display: block;
  width: 150px;
  height: 1px;
  margin: 0 auto 16px;
  background-color: #a7a7a7;
}
.agent-card__role {
  color: #333;
}
.agent-card__view {
  margin-top: 8px;
}
.agent-card__phone {
  color: #333;
  margin-top: 8px;
}

.grid.grid_cols_4 > .grid__item.agent-card {
  margin-bottom: 48px;
}

.agent-card__name:before {
  content: none;
}

.agent-card__photo {
  border: none;
  border-radius: 0;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.article-card__main-link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.article-card__image {
  border-radius: 3px;
  overflow: hidden;
}

.article-card__details {
  margin-top: 16px;
}

.article-card__heading {
  font-size: 18px;
  font-weight: bold;
  color: #292f3f;
  margin-bottom: 8px;
}

.article-card__summary {
  margin-bottom: 8px;
  color: #333;
}

.article-card__more {
  color: #292f3f;
}

.article-card__group {
  margin-bottom: 8px;
}

.article-card__date {
  display: inline-block;
  margin-right: 8px;
  color: #333;
}

.article-card__category {
  display: inline-block;
  position: relative;
  z-index: 2;
  text-decoration: none;
  color: #1F2E4F;
}

.article-card__category:hover {
  color: #1F2E4F;
}

.article-card__main-link:hover ~ .article-card__details > .article-card__heading {
  color: #1F2E4F;
}

.article-card__main-link:hover ~ .article-card__details > .article-card__summary {
  color: #1F2E4F;
}

.article-card__main-link:hover ~ .article-card__details > .article-card__more {
  color: #1F2E4F;
}

.article-card__main-link:hover ~ .article-card__details > .article-card__group > .article-card__date {
  color: #1F2E4F;
}

@media (min-width: 1200px) {
  .article-card {
    display: flex;
  }

  .article-card__image-container {
    flex-basis: 160px;
    flex-shrink: 0;
    margin-right: 16px;
  }

  .article-card__details {
    margin-top: 0;
  }
}
.article-card {
  position: relative;
  flex-direction: column;
}

.article-card__image {
  padding-bottom: 100%;
  flex: 0;
  border-radius: 3px;
}

.article-card__details {
  margin-top: 16px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
@media (min-width: 768px) {
  .grid.grid_cols_4 {
    width: calc(100% + 32px);
  }
}
@media (min-width: 1200px) {
  .grid.grid_cols_4 {
    width: calc(100% + 32px / 2);
    margin-bottom: -16px;
  }
}

@media (min-width: 768px) {
  .grid.grid_cols_4 > .grid__item {
    width: calc(50% - 32px);
    margin-right: 32px;
  }
}
@media (min-width: 1200px) {
  .grid.grid_cols_4 > .grid__item {
    width: calc(100% / 4 - 32px / 2);
    margin-right: 16px;
    margin-bottom: 16px;
  }
  .grid.grid_cols_4 > .grid__item:nth-of-type(2n) {
    margin-right: 16px;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.grid.grid_layout_2-3_1-3.grid_reverse_mobile {
  flex-direction: column-reverse;
}
@media (min-width: 1200px) {
  .grid.grid_layout_2-3_1-3.grid_reverse_mobile {
    flex-direction: row;
  }
  .grid.grid_layout_2-3_1-3 > .grid__item:nth-child(2n-1) {
    width: 66.6666666667%;
    padding-right: 16px;
  }
  .grid.grid_layout_2-3_1-3 > .grid__item:nth-child(2n) {
    width: 33.3333333333%;
    padding-left: 16px;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.why__story {
  background-color: #1F2E4F;
  color: #fff;
}
.why__story .section__preheading {
  color: #fff;
}
.why__story .section__heading {
  color: #fff;
}
.why__story .button_size_small {
  background: #0094D4;
}
.why__story .button_size_small:hover {
  background: #333;
}